<template>
  <div class="min-vh-100 d-flex align-items-center" style="background-color: white">
    <div class="text-center p-4">
      <img src="/img/staff_assist.png" class="img-fluid" width="45%" alt="" />

      <h5 class="mt-5 font-weight-bold">
        ({{ errorCode }}) ติดต่อพนักงานของเรา<br>เพื่อขอความช่วยเหลือ
      </h5>
      <span class="text-muted">Please contact our staff for assistance</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    errorCode() {
      let code = this.$route.query.errorCode
      return code
    }
  }
}
</script>